import React from "react"

const FrontMatter = () => (
  <div
    style={{
      background: `#f5e1ee`,
      color: `#34282C`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `0.8rem 1.0875rem 0.8rem`,
      }}
    >
      Welcome to Vancouver's curated cinema listing site. We source only the
      best upcoming films and filter out the mainstream noise for the discerning
      viewer.
    </div>
  </div>
)

export default FrontMatter
