import React from "react"
import BackgroundImage from "gatsby-background-image"
import { Link } from "gatsby"
import "../styles/highlights.css"

const Highlights = props => {
  const { content }  = props
  return (
    <div style={{ marginBottom: 20, marginTop: 25 }}>
      <h2>Highlights</h2>
      <ul style={{ margin: 0, textTransform: "uppercase" }}>
        {content.map(item => (
          <Link
            to={item.node.frontmatter.path}
            key={item.node.id}
            className="highlights-link"
          >
            <BackgroundImage
              fixed={item.node.frontmatter.tmdb[0].backdrop_path.localFile.childImageSharp.fixed}
              style={{
                marginRight: 10,
                border: `2px solid #f5e1ee`,
              }}
            >
              <li
                style={{
                  width: 215,
                  height: 95,
                  float: "left",
                  listStyleType: "none",
                  color: `white`,
                  background: `rgba(88, 0, 0, 0.4)`,
                  filter: "grayscale(60%)",
                }}
              >
                <span
                  style={{ display: "block", marginTop: 75, marginLeft: 6 }}
                >
                  {item.node.frontmatter.tmdb[0].title}
                </span>
              </li>
            </BackgroundImage>
          </Link>
        ))}
      </ul>
      <div style={{ clear: "both" }} />
    </div>
  )
}

export default Highlights
