import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Header from "./header"
import FrontMatter from "./FrontMatter"
import Feature from "./feature"
import "../styles/layout.css"

const LayoutHome = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteHomeTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <FrontMatter />
        <Feature />
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `0px 1.0875rem 1rem`,
            paddingTop: 0,
          }}
        >
          <main>{children}</main>
          <footer style={{ fontSize: `0.8rem`, color: `#c0c0c0`, marginTop: 20 }}>
            © {new Date().getFullYear()} ATY Creative
          </footer>
        </div>
      </>
    )}
  />
)

LayoutHome.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutHome
