import React from "react"
import FullCalendar from "@fullcalendar/react"
import listPlugin from "@fullcalendar/list"
import { formatDate } from "../utils/formatDate"
import { Link } from "gatsby"
import CalendarLegend from "../components/CalendarLegend"
import styled from "@emotion/styled"

export const StyleWrapper = styled.div`
  .fc-list-empty {
    min-height: 200px;
  }
  .fc .fc-list-table tbody > tr:first-child th {
    display: none;
  }
  .fc-theme-standard .fc-list {
    border: none;
  }
  .fc .fc-list-table tbody > tr:nth-child(2) {
    border-top: 2px solid white;
  }
`

const colors = {
  themeOne: {
    rio: "#a2b9bc",
    viff: "#6b5b95",
    pac: "#878f99",
  },
  themeTwo: {
    rio: "#99BCBC",
    viff: "#f5e1ee",
    pac: "#580000",
    fifth: "khaki",
    tinsel: "dimgray",
    scotia: "olive",
  },
}

class Today extends React.Component {
  constructor(props) {
    super(props)
    this.handleEventClick = this.handleEventClick.bind(this)
  }

  handleEventClick(e) {
    if (e.event.extendedProps.location === "The Cinematheque") {
      window.open(
        "http://thecinematheque.ca" + e.event.extendedProps.description
      )
    } else if (e.event.extendedProps.location === "Vancity Theatre") {
      window.open(
          e.event.extendedProps.description
      )
    } else if (
      e.event.extendedProps.location === "Fifth Avenue Cinema" ||
      e.event.extendedProps.location === "International Village" ||
      e.event.extendedProps.location === "Scotiabank Theatre"
    ) {
      var cineplexUrlSlug = e.event.title
        .replace(/ *\([^)]*\) */g, "")
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "")
      window.open("https://www.cineplex.com/Movie/" + cineplexUrlSlug)
    } else if (e.event.extendedProps.location.includes("Rio")) {
      var urlSlug = e.event.title
        .replace(/ *\([^)]*\) */g, "")
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "")
      window.open("https://riotheatre.ca/movie/" + urlSlug)
    }
  }

  render() {
    const rioDates = formatDate(this.props.events.rio.edges)
    const viffDates = formatDate(this.props.events.viff.edges)
    const cinemathequeDates = formatDate(this.props.events.cinematheque.edges)
    const fifthDates = formatDate(this.props.events.fifth.edges)
    const tinselDates = formatDate(this.props.events.tinsel.edges)
    const scotiaDates = formatDate(this.props.events.scotia.edges)

    return (
      <>
        <br /><h2>Today</h2>
        {typeof window !== "undefined" && (
          <StyleWrapper>
          <FullCalendar
            headerToolbar={false}
            initialView="listDay"
            plugins={[listPlugin]}
            eventClick={this.handleEventClick}
            eventSources={[
              {
                events: viffDates,
                color: colors.themeTwo.viff,
                textColor: "#444",
              },
              {
                events: cinemathequeDates,
                color: colors.themeTwo.pac,
              },
              {
                events: rioDates,
                color: colors.themeTwo.rio,
              },
              {
                events: fifthDates,
                color: colors.themeTwo.fifth,
              },
              {
                events: tinselDates,
                color: colors.themeTwo.tinsel,
              },
              {
                events: scotiaDates,
                color: colors.themeTwo.scotia,
              },
            ]}
            listDayFormat={false}
            height={"auto"}
          />
          </StyleWrapper>
        )}
        <br />
        <CalendarLegend />
        <Link to="/calendar">
          View the full calendar listings for more information
        </Link>
      </>
    )
  }
}

export default Today
