import React from "react"
import LayoutHome from "../components/LayoutHome"
import SEO from "../components/seo"
import Highlights from "../components/highlights"
import Today from "../components/today"
import { graphql } from "gatsby"

const IndexPage = ({ data }) => {
  return (
    <LayoutHome>
      <SEO
        title="Vancouver Cinema"
        keywords={[
          `cinema`,
          `listings`,
          `Vancouver`,
          `movies`,
          `film`,
          `The Cinematheque`,
          `The Rio`,
          `Vancity Theatre`,
        ]}
      />
      <Highlights content={data.highlights.edges}  />
      <Today events={data} />
    </LayoutHome>
  )
}

export default IndexPage

export const pageQuery = graphql`
         query {
           rio: allIcal(
             filter: {
               location: { regex: "/Rio/" }
               summary: {
                 ne: "Private Event"
                 regex: "/^(?:(?!Present|Improv|Burlesque|Show|Tour|Evening|Band|Live|Fundraiser|Savage).)*$/"
               }
             }
           ) {
             edges {
               node {
                 summary
                 start
                 location
                 description
               }
             }
           }
           viff: allIcal(
             filter: {
               location: { regex: "/Vancity/" }
             }
           ) {
             edges {
               node {
                 summary
                 start
                 location
                 description
               }
             }
           }
           tinsel: allIcal(
            filter: { location: { regex: "/International/" } }
          ) {
            edges {
              node {
                summary
                start
                location
              }
            }
          }
           cinematheque: allIcal(
             filter: {
               location: { regex: "/Cinematheque/" }
               summary: { ne: "Rental Event" }
             }
           ) {
             edges {
               node {
                 summary
                 start
                 location
                 description
               }
             }
           }
           fifth: allIcal(
             filter: {
               location: { regex: "/Fifth/" }
               summary: { ne: "Rental Event" }
             }
           ) {
             edges {
               node {
                 summary
                 start
                 location
                 description
               }
             }
           }
           scotia: allIcal(
            filter: {
              location: { regex: "/Scotia/" }
              summary: { ne: "Rental Event" }
            }
          ) {
            edges {
              node {
                summary
                start
                location
                description
              }
            }
          }
           highlights: allMarkdownRemark(
             filter: {
               fileAbsolutePath: { regex: "/highlights/" }
               frontmatter: { 
                tags: { 
                  eq: "active" 
                },
                cal: {elemMatch: {start: {ne: null}}}
              }
             }
             sort: { fields: [frontmatter___date], order: DESC }
             limit: 4
           ) {
             edges {
               node {
                 id
                 frontmatter {
                   path
                   tmdb {
                     title
                     tagline
                     overview
                     backdrop_path {
                       localFile {
                       childImageSharp {
                         fixed(width: 220, height: 100) {
                           ...GatsbyImageSharpFixed
                         }
                       }
                      }
                     }
                   }
                 }
                 html
               }
             }
           }
         }
       `
