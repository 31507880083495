import React from "react"
import BackgroundImage from "gatsby-background-image"
import "../styles/feature.css"
import { StaticQuery, graphql } from "gatsby"

export default () => (
  <StaticQuery
    query={graphql`
      query HighlightQuery {
        allMarkdownRemark(
          filter: {
            fileAbsolutePath: { regex: "/features/" }
            frontmatter: { tags: { in: ["active"] } }
          }
          sort: { fields: [frontmatter___date], order: DESC }
          limit: 1
        ) {
          edges {
            node {
              frontmatter {
                title
                path
                thumbnail {
                  childImageSharp {
                    fluid(maxWidth: 650, maxHeight: 250) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              html
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <BackgroundImage
          fluid={
            data.allMarkdownRemark.edges[0].node.frontmatter.thumbnail
              .childImageSharp.fluid
          }
        >
          <div
            className="feature"
            style={{
              background: `rgba(88, 0, 0, 0.6)`,
            }}
          >
            <div
              style={{
                margin: `0 auto`,
                padding: `1.3rem 1.0875rem 0.3rem`,
                color: `white`,
                maxWidth: 960,
              }}
            >
              <h3
                style={{
                  color: `white`,
                  textShadow: `none`,
                }}
              >
                {data.allMarkdownRemark.edges[0].node.frontmatter.title}
              </h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.allMarkdownRemark.edges[0].node.html,
                }}
              />
            </div>
          </div>
        </BackgroundImage>
      </>
    )}
  />
)
